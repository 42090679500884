import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import "./register.css";
import { encryptPassword } from "../../../utils/encryption";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../store/auth/authSlice";
import * as Yup from "yup";
import { useFormik } from "formik";

const validateSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("This field is required"),
    password: Yup.string()
      .required("This field is required")
      .min(8, "Password must be 8 or more characters")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])\w+/,
        "Password should contain at least one uppercase and lowercase character"
      )
      .matches(/\d/, "Password should contain at least one number")
      .matches(
        /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
        "Password should contain at least one special character"
      ),
    confirmPassword: Yup.string()
      .required("This field is required")
      .oneOf([Yup.ref("password")], "The passwords do not match"),
  });

function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubscribed, setSubscribed] = useState(false);
  const [type, setType] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

   const handleMouseDownConfirmPassword = (event) => {
     event.preventDefault();
  };
  
  const handleEncrypt = (text) => {
    const encrypted = encryptPassword.encrypt(text);
    return encrypted;
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const encryptedPassword = handleEncrypt(password);
  //   if (email && encryptedPassword) 
  

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validateSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const encryptedPassword = handleEncrypt(values.password);
      const reqBody = {
        email: values.email,
        password: encryptedPassword,
        type: type,
        is_subscribed: isSubscribed,
      };

      dispatch(registerUser(reqBody)).then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          sessionStorage.setItem("userEmail", values.email);
          toast.success(res?.data?.message || "Registration successful!");
          navigate("/email-verification");
        } else {
          toast.error(res?.data?.message || "Registration failed");
        }
        resetForm();
      });
    },
  });

  

  return (
    <>
      <Box
        sx={{
          border: "0.5px solid #D8D8DF",
          width: "936px",
          minHeight: "462px",
          position: "relative",
          overflow: "hidden",
          top: -15,
          bgcolor: "#FFF",
          boxShadow:
            "3px 10px 3px 0px rgba(0, 0, 0, 0.00), 2px 7px 3px 0px rgba(0, 0, 0, 0.01), 1px 4px 2px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Box sx={{ padding: "48px" }}>
          <div className="loginfom">
            <h1 className="headline">Welcome to VerkaufAllen!</h1>
            <p className="subtitle">
              Please enter your credentials to access your account.
            </p>
            <Stack spacing={2} direction="row" marginTop={4}>
              <Button
                sx={{
                  bgcolor: type === 0 && "#1976d2",
                  color: type === 0 && "#fff",
                }}
                onClick={() => setType(0)}
                variant="outlined"
              >
                Private
              </Button>
              <Button
                sx={{
                  bgcolor: type === 1 && "#1976d2",
                  color: type === 1 && "#fff",
                }}
                onClick={() => setType(1)}
                variant="outlined"
              >
                Commercial
              </Button>
            </Stack>
            <form onSubmit={formik.handleSubmit} className="form">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12} sm={3} display="flex" alignItems="center">
                  <FormControl variant="standard">
                    <InputLabel
                      htmlFor="input-with-icon-adornment"
                      sx={{ marginLeft: "30px" }}
                    >
                      Email Id
                    </InputLabel>
                    <Input
                      sx={{ width: "200px" }}
                      id="input-with-icon-adornment"
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      error={Boolean(formik.errors.email)}
                      startAdornment={
                        <InputAdornment position="start">
                          <EmailOutlinedIcon />
                        </InputAdornment>
                      }
                    />
                    {formik.errors.email && (
                      <Typography color="error">
                        {formik.errors.email}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={3} display="flex" alignItems="center">
                  <FormControl variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-password"
                      sx={{ marginLeft: "30px" }}
                    >
                      Password
                    </InputLabel>
                    <Input
                      sx={{ width: "200px" }}
                      id="standard-adornment-password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      error={Boolean(formik.errors.password)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <HttpsOutlinedIcon />
                        </InputAdornment>
                      }
                    />
                    {formik.errors.password && (
                      <Typography color="error">
                        {formik.errors.password}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={3} display="flex" alignItems="center">
                  <FormControl variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-confirm-password"
                      sx={{ marginLeft: "30px" }}
                    >
                      Confirm Password
                    </InputLabel>
                    <Input
                      sx={{ width: "200px" }}
                      id="standard-adornment-confirm-password"
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      onChange={formik.handleChange}
                      value={formik.values.confirmPassword}
                      error={Boolean(formik.errors.confirmPassword)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <HttpsOutlinedIcon />
                        </InputAdornment>
                      }
                    />
                    {formik.errors.confirmPassword && (
                      <Typography color="error">
                        {formik.errors.confirmPassword}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={3} marginTop={1}>
                <Grid item xs={12} sm={6} display="flex" alignItems="center">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Switch
                      onChange={(e) => setSubscribed(e.target.checked)}
                      inputProps={{ "aria-label": "subscribe switch" }}
                      size="small"
                    />
                    <Typography
                      sx={{
                        color: "#707187",
                        fontFamily: "Work Sans",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "18px",
                      }}
                    >
                      Yes, I look forward to receiving regular updates by email
                      - you can unsubscribe at any time.
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} display="flex" alignItems="center">
                  <FormControlLabel control={<Checkbox size="small" />} />
                  <Typography
                    sx={{
                      color: "#707187",
                      fontFamily: "Work Sans",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      marginLeft: "-12px",
                    }}
                  >
                    By signing up, you agree to our terms and conditions and
                    Privacy Policy.
                  </Typography>
                </Grid>

                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    width: "300px",
                    marginTop: "30px",
                    marginLeft: "20px",
                  }}
                  disabled={loading}
                >
                  Register
                </Button>
              </Grid>
            </form>
          </div>
          <div className="bottom">
            <div className="otherOption">
              <p> Or continue with</p>
            </div>
            <Divider className="horizontalLine" />
            <Stack
              marginLeft={2}
              marginTop={2}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Button
                startIcon={<GoogleIcon />}
                sx={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  textTransform: "none",
                  fontFamily: "Work Sans",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "22px",
                }}
              >
                Continue With Google
              </Button>
              <Button
                startIcon={<FacebookOutlinedIcon />}
                sx={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  textTransform: "none",
                  fontFamily: "Work Sans",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "22px",
                }}
              >
                Continue With Facebook
              </Button>
              <Button
                startIcon={<AppleIcon />}
                sx={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  textTransform: "none",
                  fontFamily: "Work Sans",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "22px",
                }}
              >
                Continue With Apple
              </Button>
            </Stack>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default Register;
