import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isAuthenticated: localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN)
    ? true
    : false,
  data: {},
  status: null,
  message: null,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      localStorage.setItem(
        process.env.REACT_APP_LOGIN_TOKEN,
        action?.payload?.data?.token
      );
      localStorage.setItem(
        process.env.REACT_APP_USER_DATA,
        JSON.stringify(action?.payload?.data)
      );
      state.isAuthenticated = action?.payload?.isAuthenticated;
      state.data = action?.payload?.data;
      state.token = action?.payload?.token;
    },
    authenticate: (state, action) => {
      state.isAuthenticated = action?.payload?.isAuthenticated;
    },
    logout: (state) => {
      localStorage.clear();
      state.isAuthenticated = false;
      state.data = {};
    },
  },
});
const authApi = `${process.env.REACT_APP_BASE_URL}`;
const token = localStorage.getItem("loginToken");
export const registerUser = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/register`, reqBody);
    if (response?.data?.status) {
      dispatch(
        login({
          data: response?.data?.data,
          isAuthenticated: false,
        })
      );
    }
    return response;
  } catch (error) {}
};

export const loginUser = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/signin`, reqBody);
    if (response?.data?.status) {
      dispatch(
        login({
          data: response?.data?.data,
          isAuthenticated: true,
        })
      );
    }
    return response;
  } catch (error) {}
};

export const forgotPassword = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/forget-password`, reqBody);
    return response;
  } catch (error) {}
};

export const changePassword = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/changepassword`, reqBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {}
};

export const verifyEmailOtp =
  (reqBody, shouldRedirectHome = false) =>
  async (dispatch) => {
    try {
      const response = await axios.post(`${authApi}/verify-email`, reqBody);
      if (response?.data?.status && shouldRedirectHome) {
        dispatch(
          authenticate({
            isAuthenticated: true,
          })
        );
      }
      return response?.data;
    } catch (error) {}
  };

export const resendOtp = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/send-otp`, reqBody);
    return response;
  } catch (error) {}
};

export const resetPassword = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/reset-password`, reqBody);
    return response;
  } catch (error) {}
};

export const { login, logout, authenticate } = AuthSlice.actions;

export default AuthSlice.reducer;
