import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const GET_PROFILE_BY_ID = `${process.env.REACT_APP_BASE_URL}/profile/getbyid`;
const UPDATE_PROFILE = `${process.env.REACT_APP_BASE_URL}/profile/edit`;

const getToken = () => localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

const initialState = {
  data: [],
  status: null,
  message: null,
  faqData: [],
};

export const ProfileSlice = createSlice({
  name: "ProfileList",
  initialState,
  reducers: {
    profileDetailSuccess: (state, action) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    profileDetailFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    updateProfileSuccess: (state, action) => {
      state.data = action.payload.data;
      state.message = action.payload.data;
    },
    updateProfileFailure: (state, action) => {
      state.data = action.payload.data;
      state.message = action.payload.data;
    },
    faqListSuccess: (state, action) => {
      state.faqData = action.payload.data;
    },
  },
});

export const {
  profileDetailSuccess,
  profileDetailFailure,
  updateProfileSuccess,
  updateProfileFailure,
  faqListSuccess,
} = ProfileSlice.actions;

export const getProfileById = (id) => async (dispatch) => {
  const token = getToken();
  try {
    const response = await axios.get(`${GET_PROFILE_BY_ID}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      profileDetailSuccess({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      profileDetailFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const updateProfile = (id, profileData) => async (dispatch) => {
  const token = getToken();
  try {
    const response = await axios.put(
      `${UPDATE_PROFILE}`,
      JSON.stringify(profileData),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(
      updateProfileSuccess({
        status: response.data.status,
        message: response.data.message,
      })
    );

    // dispatch(fetchCategoryList());
    dispatch(getProfileById(id));
    return response?.data;
  } catch (error) {
    dispatch(
      updateProfileFailure({
        status: "false",
        message: error.message,
      })
    );
  }
};

export const getFaqList = () => async (dispatch) => {
  const token = getToken();
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/faqs`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(
      faqListSuccess({
        data: response.data.data,
      })
    );
    return response;
  } catch (error) {}
};

export const addSuggestion = (reqBody) => async (dispatch) => {
  const token = getToken();
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/suggestion/add`,
      reqBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};

export default ProfileSlice.reducer;
