import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Stack,
  styled,
  alpha,
  InputBase,
} from "@mui/material";
import CategoryList from "../CategoryList/CategoryList";
import Header from "../Header/Header";
import SearchIcon from "@mui/icons-material/Search";
import { getCategoryById } from "../../store/helper/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const demoImg =
  "https://s3-alpha-sig.figma.com/img/6eb6/4ba2/ba2906440a85e4d8dcaa3d081dd0ffcf?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SoU3-RVHvchgLx5yiTxzX2WB14X7ExsroEc-HApapxUp7kFexu~W6fw0AMW3DPqmoOqpA0PEdTj-ZSJGOGBQnFfX034VNIxKCs9BwGr5vQqIcLbSuRpdDJXZolBXKB8sxkiKNRTaVNlCxhdh1kKHgKR2BrYPr3Wugmeot01EHV4QQF0m~ySYjOfyj1RNVKoR6ZrnMZv7dDXRHGr8wooPjtr74YjwzsHqmnWrmiONBUo4jdosdjygZ2NZ1DAXl8uPtj0yTsytiDqkJmlGTjcbh9A3yDM8q8YHJy5GBQdQ0e2L1IgN0Wt8DEs1WTYRLxTXDGNfRuJIw-hN1TQN~V5-Sw__";

const CategoryCard = () => {
  const [value, setValue] = useState(0);
  const [valueTab, setValueTab] = useState(0);
  // const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();

  const categoriesData = useSelector((state) => state.categoryReducer || []);

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: "auto",
    marginRight: theme.spacing(2),
    width: "auto",
  }));

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    const selectedCategoryId = categoriesData?.subCategoryData?.[newValue]?.id;
    dispatch(getCategoryById(selectedCategoryId, true));
  };

  useEffect(() => {
    if (id) {
      dispatch(getCategoryById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (categoriesData?.subCategoryData?.length > 0) {
      dispatch(getCategoryById(categoriesData.subCategoryData[0].id, true));
    }
  }, [dispatch, categoriesData?.subCategoryData]);

  return (
    <>
      <Header />
      <Box
        sx={{
          margin: "15px",
          height: "100%",
          border: "1px solid #D8D8DF",
          borderRadius: "4px",
        }}
      >
        <Stack>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "background.paper",
              borderBottom: 1,
              borderColor: "divider",
              paddingTop: "20px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontSize: "19px",
                paddingLeft: "10px",
              }}
            >
              Pets Categories
            </Typography>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Box>
          <Tabs
            value={valueTab}
            onChange={(e, newValue) => setValueTab(newValue)}
            centered
            sx={{ marginTop: "-47px" }}
          >
            <Tab label="A - Z Arrangement" />
            <Tab label="Z - A Arrangement" />
            <Tab label="Item Three" />
          </Tabs>
        </Stack>
        <Stack>
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleTabChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                width: "138px",
                backgroundColor: "#F7F7F8",
              }}
            >
              {categoriesData?.subCategoryData?.map((category) => (
                <Tab
                  key={category.id}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={category.icon || demoImg}
                        alt={category.name}
                        sx={{
                          width: 40,
                          height: 40,
                          marginBottom: 1,
                          borderRadius: 30,
                        }}
                      />
                      {category.name}
                    </Box>
                  }
                />
              ))}
            </Tabs>

            {categoriesData?.subCategoryData?.map((category, i) => (
              <TabPanel key={category.id} value={value} index={i}>
                {valueTab === 0 ? (
                  <CategoryList categoryId={category.id} />
                ) : (
                  <Typography>
                    {category.description || `Item ${i + 1}`}
                  </Typography>
                )}
              </TabPanel>
            ))}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default CategoryCard;
