import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

// API of prduct
const CATEGORY_LIST = `${process.env.REACT_APP_BASE_URL}/categories`;
const GET_CATEGORY_BY_ID = `${process.env.REACT_APP_BASE_URL}/categories`;

const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

const initialState = {
  mainCategorydata: [],
  status: null,
  message: null,
  subCategoryData: [],
  subSubcategoryData: [],
};

export const CategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    categoryListSuccess: (state, action) => {
      state.mainCategorydata = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    categoryListFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    categoryDetailsSuccess: (state, action) => {
      state.subCategoryData = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    subSubcategoryDetailsSuccess: (state, action) => {
      state.subSubcategoryData = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    categoryDetailsFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  categoryListSuccess,
  categoryListFailure,
  categoryDetailsSuccess,
  subSubcategoryDetailsSuccess,
  categoryDetailsFailure,
} = CategorySlice.actions;

export const fetchCategoryList = () => async (dispatch) => {
  try {
    const response = await axios.get(CATEGORY_LIST, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      categoryListSuccess({
        data: response?.data?.data,
        status: response.data.status,
        message: response.data.message,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      categoryListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const getCategoryById =
  (id, isSubSubCategory = false) =>
  async (dispatch) => {
    try {
      const response = await axios.get(`${GET_CATEGORY_BY_ID}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (isSubSubCategory) {
        await dispatch(
          subSubcategoryDetailsSuccess({
            status: response.data.status,
            message: response.data.message,
            data: response.data.data,
          })
        );
      } else {
        await dispatch(
          categoryDetailsSuccess({
            status: response.data.status,
            message: response.data.message,
            data: response.data.data,
          })
        );
      }
      return response;
    } catch (error) {
      dispatch(
        categoryDetailsFailure({
          status: false,
          message: error.message,
        })
      );
    }
  };

export default CategorySlice.reducer;
