import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Popper,
  Paper,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  ImageListItem,
  TextField,
  MenuItem,
} from "@mui/material";
import styled from "@emotion/styled";

// Icons
import dropIcon from "../../assets/images/CategoryImages/arrow-down.png";
import uploadIcon from "../../assets/images/MarkePlace/uploadImage.svg";

// styles
const Text = styled(Typography)`
  color: #26252c;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const DropDown = styled(Box)`
  width: 100%;
  max-width: 644px;
  height: 40px;
  flex-shrink: 0;
  border-bottom: 1px solid #d8d8df;
  background: #f7f7f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
`;

const SelectCategory = styled(Typography)`
  color: #707187;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-left: 16px;
`;

const SearchBar = styled(InputBase)`
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid #d8d8df;
`;

const PopperContainer = styled(Paper)`
  width: 100%;
  max-width: 644px;
  padding: 8px;
`;

const HRLine = styled(Divider)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

// Title,description, price
const FormHeadingText = styled(Typography)`
  color: var(--Token-Text-Text-secondary, #707187);
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
`;

const TextInput = styled(TextField)`
  & .MuiInputBase-root {
    border-radius: 0px;
    border-bottom: 2px solid #00a6e8;
    height: 34px;
  }
  & .MuiInputBase-input {
    border: none;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

function Dropdown({ options, selectedCategory, onSelect }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    const filtered = options.filter((option) =>
      option.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleSelect = (option) => {
    onSelect(option);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <div>
      <DropDown onClick={handleClick}>
        <SelectCategory>{selectedCategory}</SelectCategory>
        <img
          src={dropIcon}
          alt="drop icon"
          style={{ width: "24px", height: "24px" }}
        />
      </DropDown>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <PopperContainer>
          <SearchBar
            placeholder="Search..."
            value={search}
            onChange={handleSearchChange}
          />
          <Typography variant="h6" component="div" sx={{ mt: 2, mb: 1 }}>
            Select Category
          </Typography>
          <List>
            {filteredOptions.map((option, index) => (
              <ListItem button key={index} onClick={() => handleSelect(option)}>
                <ListItemText primary={option} />
              </ListItem>
            ))}
          </List>
        </PopperContainer>
      </Popper>
    </div>
  );
}

const UploadBox = styled(Box)(({ theme }) => ({
  border: "1px solid #D8D8DF",
  background: "#FFF",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
  cursor: "pointer",
  marginBottom: "16px",
  marginRight: "16px",
}));

const SelectedImagesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  gap: "16px",
  height: "133px",
}));

const ImageItem = styled(ImageListItem)(({ theme }) => ({
  width: "180px",
  height: "100px",
  objectFit: "cover",
}));

const UploadImages = ({ onImagesSelected, selectedImages }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    onImagesSelected(files);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Grid container>
      <UploadBox onClick={handleUploadClick}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="upload-button"
          type="file"
          multiple
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <img
          src={uploadIcon}
          alt="upload icon"
          style={{
            height: "40px",
            width: "40px",
            paddingTop: "23px",
            paddingBottom: "23px",
          }}
        />
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Work Sans",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "115%",
            paddingLeft: "38px",
            paddingRight: "38px",
          }}
        >
          Upload Images
        </Typography>
      </UploadBox>
      <SelectedImagesContainer>
        {selectedImages.map((image, index) => (
          <ImageItem key={index}>
            <img src={URL.createObjectURL(image)} alt={`selected ${index}`} />
          </ImageItem>
        ))}
      </SelectedImagesContainer>
    </Grid>
  );
};

function MarketPlace({ setMarketFieldTextFilled }) {
  const categories = [
    "Marketplace",
    "Automotive",
    "Properties",
    "Pets",
    "Bicycle & Spare Parts",
    "Electric Vehicle",
  ];

  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [selectedImages, setSelectedImages] = useState([]);

  //   States
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [priceType, setPriceType] = useState("");
  const [amount, setAmount] = useState("");
  const [condition, setCondition] = useState("");
  const [descriptive, setDescriptive] = useState("");

  const handleImagesSelected = (images) => {
    if (selectedImages.length === 5) {
      alert("Can't select more than 5 images");
      return;
    }
    setSelectedImages((prevImages) => [...prevImages, ...images]);
  };

  const handleConditionChange = (event) => {
    setCondition(event.target.value);
    console.log("Selected Condition:", event.target.value);
  };

  useEffect(() => {
    if (title && priceType && amount && condition) {
      setMarketFieldTextFilled(false);
    } else {
      setMarketFieldTextFilled(true);
    }
  }, [title, priceType, amount, condition, description, descriptive]);

  return (
    <Box sx={{ mt: "24px" }}>
      <Text>Select Sub Category</Text>
      <Dropdown
        options={categories}
        selectedCategory={selectedCategory}
        onSelect={setSelectedCategory}
      />

      <HRLine />

      {selectedCategory !== "Select Category" && (
        <>
          <UploadImages
            onImagesSelected={handleImagesSelected}
            selectedImages={selectedImages}
          />
        </>
      )}

      {/* Title, description, Price Type, Amount (Euro)*, Condition, Descriptive Paragraph */}
      {selectedImages.length >= 1 && (
        <Grid container spacing={2} mt={"32px"}>
          {/* Title and Description */}
          <Grid item xs={12} md={4} sm={6}>
            <FormHeadingText placeholder={"Title"}>Title</FormHeadingText>
            <TextInput fullWidth onChange={(e) => setTitle(e.target.value)} />
          </Grid>
          <Grid item xs={12} md={8} sm={6}>
            <FormHeadingText>Description</FormHeadingText>
            <TextInput
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>

          {/*  Price Type, Amount (Euro), Condition */}
          <Grid item xs={12} sm={4} mt="24px">
            <FormHeadingText>Price Type</FormHeadingText>
            <TextInput
              fullWidth
              select
              onChange={(e) => setPriceType(e.target.value)}
            >
              <MenuItem value="0">Fixed Price</MenuItem>
              <MenuItem value="1">Negotiable</MenuItem>
              <MenuItem value="2">Upon Request</MenuItem>
              <MenuItem value="3">To give away</MenuItem>
            </TextInput>
          </Grid>
          <Grid item xs={12} sm={4} mt="24px">
            <FormHeadingText>Amount (Euro)</FormHeadingText>
            <TextInput
              fullWidth
              type="number"
              onChange={(e) => setAmount(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} mt="24px">
            <FormHeadingText>Condition</FormHeadingText>
            <TextInput fullWidth select onChange={handleConditionChange}>
              <MenuItem value="0">New / Unused - as good as new</MenuItem>
              <MenuItem value="1">Used - Like New</MenuItem>
              <MenuItem value="2">Used, Good condition</MenuItem>
              <MenuItem value="3">Used - Relatively good condition</MenuItem>
              <MenuItem value="4">Defect - Broken or Poor condition</MenuItem>
            </TextInput>
          </Grid>

          {/*  Descriptive Paragraph */}
          <Grid item xs={12} md={12} mt="24px">
            <FormHeadingText>Descriptive Paragraph</FormHeadingText>
            <TextInput
              fullWidth
              onChange={(e) => setDescriptive(e.target.value)}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default MarketPlace;
