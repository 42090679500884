import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import Email from "../../../assets/images/LoginImages/mail.png";
import Password from "../../../assets/images/LoginImages/password.png";
import { loginUser } from "../../../store/auth/authSlice";
import { useDispatch } from "react-redux";
import { encryptPassword } from "../../../utils/encryption";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Personal email is required"),
  password: yup
    .string()
    .required("This field is required")
    .min(8, "Password must be 8 or more characters")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])\w+/,
      "Password should contain at least one uppercase and lowercase character"
    )
    .matches(/\d/, "Password should contain at least one number")
    .matches(
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
      "Password should contain at least one special character"
    ),
});

function Login() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        resetForm();
      }, 1000 * 2);
    },
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const passwordLink = () => {
    navigate("/forgot-password");
  };

  const handleEncrypt = (text) => {
    const encrypted = encryptPassword.encrypt(text);
    return encrypted;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const encryptedPassword = handleEncrypt(formik.values.password);
    if (formik.values.email && encryptedPassword) {
      const reqBody = {
        email: formik.values.email,
        password: encryptedPassword,
      };
      dispatch(loginUser(reqBody)).then((res) => {
        if (res?.data?.status) {
          toast.success("Login successful!");
          navigate("/home");
        } else {
          toast.error(res?.data?.message || "Login failed");
        }
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "0.5px solid #D8D8DF",
          width: "936px",
          minHeight: "452px",
          position: "relative",
          overflow: "hidden",
          top: -15,
          bgcolor: "#FFF",
          boxShadow:
            "3px 10px 3px 0px rgba(0, 0, 0, 0.00), 2px 7px 3px 0px rgba(0, 0, 0, 0.01), 1px 4px 2px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Box sx={{ padding: "48px", overflow: "hidden" }}>
          <div className="loginfom">
            <h1 className="headline">Welcome to VerkaufAllen!</h1>
            <p className="subtitle">
              Please enter your credentials to access your account.
            </p>
            <form onSubmit={formik.handleSubmit} className="form">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {/* Email Id */}
                <Grid item xs={12} sm={3} display="flex" alignItems="center">
                  <FormControl
                    variant="standard"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  >
                    <InputLabel
                      htmlFor="input-with-icon-adornment"
                      sx={{ marginLeft: "30px" }}
                    >
                      Email Id
                    </InputLabel>
                    <Input
                      sx={{ width: "200px" }}
                      id="input-with-icon-adornment"
                      type={"email"}
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={Email} alt="email" className="icon" />
                        </InputAdornment>
                      }
                    />
                    {formik.touched.email && formik.errors.email && (
                      <FormHelperText>{formik.errors.email}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Password */}
                <Grid item xs={12} sm={3} display="flex" alignItems="center">
                  <FormControl
                    variant="standard"
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                  >
                    <InputLabel
                      htmlFor="standard-adornment-password"
                      sx={{ marginLeft: "30px" }}
                    >
                      Password
                    </InputLabel>
                    <Input
                      sx={{ width: "200px" }}
                      id="standard-adornment-password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={Password} alt="password" className="icon" />
                        </InputAdornment>
                      }
                    />
                    {formik.touched.password && formik.errors.password && (
                      <FormHelperText>{formik.errors.password}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3} marginTop={-2}>
                <Grid item xs={12} sm={3} display="flex" alignItems="center">
                  <Button
                    onClick={passwordLink}
                    sx={{
                      marginTop: "20px",
                      fontWeight: "bold",
                      textTransform: "none",
                      color: "#00A6E8",
                    }}
                  >
                    Forgot Password?
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3} marginTop={3}>
                  <Button
                  onClick={handleSubmit}
                    variant="contained"
                    type="submit"
                    sx={{
                      width: "300px",
                      textTransform: "none",
                      marginLeft: "-30px",
                      backgroundColor: "#00A6E8",
                      color: "white",
                    }}
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
          <div className="bottom">
            <div className="otherOption">
              <p> Or continue with</p>
            </div>
            <Divider className="horizontalLine" />
            <Stack
              marginLeft={2}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Button
                startIcon={<GoogleIcon />}
                sx={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  textTransform: "none",
                  fontFamily: "Work Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "22px",
                }}
              >
                Continue With Google
              </Button>
              <Button
                startIcon={<FacebookOutlinedIcon />}
                sx={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  textTransform: "none",
                  fontFamily: "Work Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "22px",
                }}
              >
                Continue With Facebook
              </Button>
              <Button
                startIcon={<AppleIcon />}
                sx={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  textTransform: "none",
                  fontFamily: "Work Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "22px",
                }}
              >
                Continue With Apple
              </Button>
            </Stack>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default Login;
