import React from "react";

// css
import "./ProductSlide.css";
import ProductCard from "../ProductCard/ProductCard";

// images
import Car0 from "../../assets/images/ProductImages/car0.png";
import Car1 from "../../assets/images/ProductImages/car1.png";
import Car2 from "../../assets/images/ProductImages/car2.png";
import Car4 from "../../assets/images/ProductImages/car-04.png";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

// Dummy products for Product slide
const products = [
  {
    id: 1,
    image: Car0,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "20d",
    previousPrice: "199,00",
    currentPrice: "189,00",
    sponsored: true,
  },
  {
    id: 2,
    image: Car1,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "20d",
    previousPrice: "199,00",
    currentPrice: "189,00",
    sponsored: true,
  },
  {
    id: 3,
    image: Car2,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "20d",
    previousPrice: "199,00",
    currentPrice: "189,00",
    sponsored: false,
  },
  {
    id: 4,
    image: Car2,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "20d",
    previousPrice: "199,00",
    currentPrice: "189,00",
    sponsored: false,
  },
  {
    id: 5,
    image: Car2,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "20d",
    previousPrice: "199,00",
    currentPrice: "189,00",
    sponsored: false,
  },
  {
    id: 6,
    image: Car2,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "20d",
    previousPrice: "199,00",
    currentPrice: "189,00",
    sponsored: false,
  },
  {
    id: 7,
    image: Car2,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "20d",
    previousPrice: "199,00",
    currentPrice: "189,00",
    sponsored: false,
  },
  {
    id: 8,
    image: Car2,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "20d",
    previousPrice: "199,00",
    currentPrice: "189,00",
    sponsored: false,
  },
  {
    id: 39,
    image: Car2,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "20d",
    previousPrice: "199,00",
    currentPrice: "189,00",
    sponsored: false,
  },
  {
    id: 10,
    image: Car2,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "20d",
    previousPrice: "199,00",
    currentPrice: "189,00",
    sponsored: false,
  },
];

function ProductSlide({ title, showDiv }) {
  return (
    <Box sx={{ marginTop: "24px" }} className="productslide-container">
      {/* product header text  */}
      <p>{title}</p>

      <Box sx={{ display: "flex" }}>
        {showDiv && (
          <Card
            sx={{
              width: 550,
              height: 314,
              overflowX: "auto",
              alignItems: "flex-start",
              gap: "16px",
              marginLeft: "16px",
              // marginRight: "16px",
              marginBottom: "16px",
              backgroundColor: "#00A6E8",
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                // height="140"
                // height="148px"
                width="172px"
                image={Car4}
                alt="green iguana"
              />
              <CardContent sx={{paddingBottom:"10px"}}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  textAlign="center"
                  color="white"
                >
                  Trendy Deal
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
        {/*  product card*/}
        <ProductCard products={products} />
      </Box>
    </Box>
  );
}

export default ProductSlide;
