import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileById,
  updateProfile,
} from "../../../store/profile/profileSlice";

import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  nickname: yup.string().required("Nickname is required"),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  dob: yup.date().required("Date of birth is required").nullable(),
  gender: yup.string().required("Gender is required"),
  personal_email: yup
    .string()
    .email("Invalid email format")
    .required("Personal email is required"),
  personal_telephone: yup.string().required("Personal telephone is required"),
  personal_address: yup.string().required("Personal address is required"),
  personal_zipcode: yup.string().required("Personal zipcode is required"),
  country: yup.string().required("Country is required"),
});

const Profile = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getProfileById(userData?.id));
  }, [dispatch]);

  const profileDetails = useSelector((state) => state?.profileReducer?.data);

  const userLocalStorageData = localStorage.getItem(
    process.env.REACT_APP_USER_DATA
  );

  const userData = JSON.parse(userLocalStorageData);
  
  const formik = useFormik({
    initialValues: {
      nickname: "",
      first_name: "",
      last_name: "",
      dob: "",
      gender: "",
      personal_email: "",
      personal_telephone: "",
      personal_address: "",
      personal_zipcode: "",
      country: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleUpdate,
  });

  useEffect(() => {
    const updatedValues = profileDetails.length === 1 ? profileDetails[0] : {};
    if (profileDetails) {
      formik.setValues({
        nickname: updatedValues?.nickname || "",
        first_name: updatedValues?.first_name || "",
        last_name: updatedValues?.last_name || "",
        dob: updatedValues?.dob || "",
        gender: updatedValues?.gender || "",
        personal_email: updatedValues?.personal_email || "",
        personal_telephone: updatedValues?.personal_telephone || "",
        personal_address: updatedValues?.personal_address || "",
        personal_zipcode: updatedValues?.personal_zipcode || "",
        country: updatedValues?.country || "",
      });
    }
    // eslint-disable-next-line
  }, [profileDetails, formik.setValues]);

  async function handleUpdate(item) {
    try {
      dispatch(updateProfile(userData?.id, item));
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  }

  return (
    <>
      <Header isLoggedIn={true} />
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={8.9} overflow="hidden" marginTop={2}>
            <Box bgcolor="white">
              <Box padding={3}>
                <Typography
                  variant="h5"
                  fontFamily="Work Sans"
                  fontSize="20px"
                  fontWeight="600"
                  fontStyle="normal"
                  lineHeight="28px"
                >
                  Profile
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="#707187"
                  fontFamily="Work Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="22px"
                  marginTop={2}
                >
                  Please fill in the following details to create your account.
                </Typography>
                <Box>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    paddingBottom={3}
                    marginTop={4}
                    // paddingTop={1}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{ width: 88, height: 88 }}
                    />

                    <Button
                      disabled
                      variant="outlined"
                      type="submit"
                      sx={{ width: 156, height: 32 }}
                      style={{ marginTop: "30px", marginLeft: "15px" }}
                    >
                      Change Image
                    </Button>
                  </Stack>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    display="flex"
                    alignItems="center"
                    gap={3}
                  >
                    <Button
                      variant="contained"
                      style={{
                        padding: "2px 40px",
                        backgroundColor: "#00A6E8",
                      }}
                    >
                      Private
                    </Button>
                    <Button variant="outlined" style={{ padding: "2px 40px" }}>
                      Commercial
                    </Button>
                  </Grid>

                  <div
                    style={{
                      color: "#26252C",
                      fontFamily: "Work Sans",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "22px",
                    }}
                  >
                    <p> Personal Information </p>
                  </div>
                  <Divider
                    className="horizontalLine"
                    style={{ marginLeft: "22px", marginTop: "-2px" }}
                  />
                  <form className="form" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                      {/* Nickname*/}
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            Nickname
                          </InputLabel>
                          <Input
                            placeholder="Enter Here"
                            id="input-with-icon-adornment"
                            name="nickname"
                            value={formik.values.nickname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            startAdornment={
                              <InputAdornment position="start">
                                {/* <img src={Email} alt="email" className="icon" /> */}
                              </InputAdornment>
                            }
                          />
                          {formik.touched.nickname && formik.errors.nickname ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {formik.errors.nickname}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>

                      {/* First Name */}
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            First Name
                          </InputLabel>
                          <Input
                            placeholder="Enter first name"
                            id="standard-adornment-password"
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                {/* <img
                                  src={Password}
                                  alt="password"
                                  className="icon"
                                /> */}
                              </InputAdornment>
                            }
                          />
                          {formik.touched.first_name &&
                          formik.errors.first_name ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {formik.errors.first_name}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>

                      {/* Last Name */}
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            Last Name
                          </InputLabel>
                          <Input
                            placeholder="Enter Here"
                            id="standard-adornment-password"
                            name="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                {/* <img
                                  src={Password}
                                  alt="password"
                                  className="icon"
                                /> */}
                              </InputAdornment>
                            }
                          />
                          {formik.touched.last_name &&
                          formik.errors.last_name ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {formik.errors.last_name}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>

                      {/* Date of Birth (Only visible for you)*/}
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            Date of Birth (Only visible for you)
                          </InputLabel>
                          <Input
                            placeholder="Enter Here"
                            id="standard-adornment-password"
                            name="dob"
                            value={formik.values.dob}
                            onChange={formik.handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                {/* <img
                                  src={Password}
                                  alt="password"
                                  className="icon"
                                /> */}
                              </InputAdornment>
                            }
                          />
                          {formik.touched.dob && formik.errors.dob ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {formik.errors.dob}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>

                      {/* Gender */}
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            Gender
                          </InputLabel>
                          <Input
                            placeholder="Enter Here"
                            id="standard-adornment-password"
                            name="gender"
                            value={formik.values.gender}
                            onChange={formik.handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                {/* <img
                                  src={Password}
                                  alt="password"
                                  className="icon"
                                /> */}
                              </InputAdornment>
                            }
                          />
                          {formik.touched.gender && formik.errors.gender ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {formik.errors.gender}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <div
                      style={{
                        color: "#26252C",
                        fontFamily: "Work Sans",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "22px",
                        marginTop: "32px",
                      }}
                    >
                      <p> Contact Information </p>
                    </div>

                    <Divider
                      className="horizontalLine"
                      style={{ marginLeft: "17px", marginTop: "-2px" }}
                    />

                    {/* Email Id (Only visible for you) */}
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                        marginTop={2}
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            Email Id (Only visible for you)
                          </InputLabel>
                          <Input
                            placeholder="Enter Here"
                            id="input-with-icon-adornment"
                            name="personal_email"
                            value={formik.values.personal_email}
                            onChange={formik.handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                {/* <img src={Email} alt="email" className="icon" /> */}
                              </InputAdornment>
                            }
                          />
                          {formik.touched.personal_email &&
                          formik.errors.personal_email ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {formik.errors.personal_email}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>

                      {/* Telephone (Only visible for you) */}
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            Telephone (Only visible for you)
                          </InputLabel>
                          <Input
                            placeholder="Enter Here"
                            id="standard-adornment-password"
                            name="personal_telephone"
                            value={formik.values.personal_telephone}
                            onChange={formik.handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                {/* <img
                                  src={Password}
                                  alt="password"
                                  className="icon"
                                /> */}
                              </InputAdornment>
                            }
                          />
                          {formik.touched.personal_telephone &&
                          formik.errors.personal_telephone ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {formik.errors.personal_telephone}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <div
                      style={{
                        color: "#26252C",
                        fontFamily: "Work Sans",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "22px",
                        marginTop: "32px",
                      }}
                    >
                      <p> Address Information </p>
                    </div>
                    <Divider
                      className="horizontalLine"
                      style={{ marginLeft: "19px", marginTop: "-2px" }}
                    />

                    {/* Address (Only visible for you) */}
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            Address (Only visible for you)
                          </InputLabel>
                          <Input
                            placeholder="Enter Here"
                            id="input-with-icon-adornment"
                            name="personal_address"
                            value={formik.values.personal_address}
                            onChange={formik.handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                {/* <img src={Email} alt="email" className="icon" /> */}
                              </InputAdornment>
                            }
                          />
                          {formik.touched.personal_address &&
                          formik.errors.personal_address ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {formik.errors.personal_address}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>

                      {/* Zip Code (Only visible for you) */}
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            Zip Code (Only visible for you)
                          </InputLabel>
                          <Input
                            placeholder="Enter Here"
                            id="standard-adornment-password"
                            name="personal_zipcode"
                            value={formik.values.personal_zipcode}
                            onChange={formik.handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                {/* <img
                                src={Password}
                                alt="password"
                                className="icon"
                              /> */}
                              </InputAdornment>
                            }
                          />
                          {formik.touched.personal_zipcode &&
                          formik.errors.personal_zipcode ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {formik.errors.personal_zipcode}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>

                      {/* Country (Only visible for you) */}
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                        // marginTop={3}
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            Country (Only visible for you)
                          </InputLabel>
                          <Input
                            placeholder="Enter Here"
                            id="standard-adornment-password"
                            name="country"
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                {/* <img
                                src={Password}
                                alt="password"
                                className="icon"
                              /> */}
                              </InputAdornment>
                            }
                          />
                          {formik.touched.country && formik.errors.country ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {formik.errors.country}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Stack
                      direction="row"
                      spacing={2}
                      marginLeft={35}
                      marginTop={15}
                    >
                      <Button
                        style={{
                          color: "black",
                          textTransform: "none",
                          fontFamily: "Work Sans",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "22px",
                          width: "328px",
                          height: "40px",
                        }}
                        variant="outlined"
                        disabled
                      >
                        Discard
                      </Button>
                      <Button
                        style={{
                          color: "black",
                          textTransform: "none",
                          fontFamily: "Work Sans",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "22px",
                          width: "328px",
                          height: "40px",
                          backgroundColor: "#00A6E8",
                          color: "white",
                        }}
                        type="submit"
                        variant="contained"
                        // onClick={handleUpdate}
                      >
                        Update Profile
                      </Button>
                    </Stack>
                  </form>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Profile;
