import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Email from "../../../assets/images/LoginImages/mail.png";
import Password from "../../../assets/images/LoginImages/password.png";
import { encryptPassword } from "../../../utils/encryption";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../store/auth/authSlice";
import { toast } from "react-toastify";

const Account = () => {
  // eslint-disable-next-line
  const [showPassword, setShowPassword] = React.useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const handleEncrypt = (text) => {
    const encrypted = encryptPassword.encrypt(text);
    return encrypted;
  };
  const handleChangePassword = (event) => {
    event.preventDefault();
    const encryptedCurrentPassword = handleEncrypt(currentPassword);
    const encryptedNewPassword = handleEncrypt(newPassword);
    const encryptedConfirmPassword = handleEncrypt(confirmPassword);
    const reqBody = {
      current_password: encryptedCurrentPassword,
      new_password: encryptedNewPassword,
      confirm_password: encryptedConfirmPassword,
    };
    dispatch(changePassword(reqBody)).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message);
      } else {
        toast.error("Something went wrong !");
      }
    });
  };
  return (
    <>
      <Header isLoggedIn={true} />
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={8.9} overflow="hidden" marginTop={2}>
            <Box bgcolor="white">
              <Box padding={3}>
                <Typography
                  variant="h5"
                  fontFamily="Work Sans"
                  fontSize="20px"
                  fontWeight="600"
                  fontStyle="normal"
                  lineHeight="28px"
                >
                  My Account
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="#707187"
                  fontFamily="Work Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="22px"
                  marginTop={2}
                >
                  Please fill in the following details to create your account.
                </Typography>
                <Box>
                  <div
                    style={{
                      marginTop: "43px",
                      color: "#26252C",
                      fontFamily: "Work Sans",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "22px",
                    }}
                  >
                    <p> General Information </p>
                  </div>
                  <Divider
                    className="horizontalLine"
                    style={{ marginLeft: "14px", marginTop: "-2px" }}
                  />
                  <FormGroup>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      paddingBottom={3}
                      // paddingTop={1}
                    >
                      <Switch
                        inputProps={{ "aria-label": "ant design" }}
                        size="small"
                      />
                      <Typography
                        style={{
                          color: "#26252C",
                          fontFamily: "Work Sans",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "18px",
                          marginLeft: "-2px",
                          marginTop: "2px",
                        }}
                      >
                        Chat Notification
                      </Typography>
                      <Switch
                        inputProps={{ "aria-label": "ant design" }}
                        size="small"
                      />
                      <Typography
                        style={{
                          color: "#26252C",
                          fontFamily: "Work Sans",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "18px",
                          marginLeft: "-2px",
                          marginTop: "2px",
                        }}
                      >
                        Activity Notification
                      </Typography>
                      <Switch
                        inputProps={{ "aria-label": "ant design" }}
                        size="small"
                      />
                      <Typography
                        style={{
                          color: "#26252C",
                          fontFamily: "Work Sans",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "18px",
                          marginLeft: "-2px",
                          marginTop: "2px",
                        }}
                      >
                        Dark Mode
                      </Typography>
                    </Stack>
                  </FormGroup>
                  <div
                    style={{
                      color: "#26252C",
                      fontFamily: "Work Sans",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "22px",
                    }}
                  >
                    <p> Select Language </p>
                  </div>
                  <Divider
                    className="horizontalLine"
                    style={{ marginLeft: "-13px", marginTop: "-2px" }}
                  />
                  <FormControl>
                    <RadioGroup
                      // row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        flex={3}
                        marginLeft="-10px"
                      >
                        <FormControlLabel
                          value="english"
                          control={<Radio />}
                          label="English"
                          style={{
                            border: "1px solid #D8D8DF",
                            margin: "10px",
                            color: "#26252C",
                            fontSize: "10px",
                            width: "229px",
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 600,
                            borderRadius: "4px",
                          }}
                        />
                        <FormControlLabel
                          value="alemana"
                          control={<Radio />}
                          label="Alemana"
                          style={{
                            border: "1px solid #D8D8DF",
                            padding: "10px 0px",
                            margin: "10px",
                            color: "#26252C",
                            fontSize: "16px",
                            width: "229px",
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 600,
                            borderRadius: "4px",
                          }}
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label="Española"
                          style={{
                            border: "1px solid #D8D8DF",
                            padding: "10px 0px",
                            margin: "10px",
                            color: "#26252C",
                            fontSize: "16px",
                            width: "229px",
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "110%",
                            borderRadius: "4px",
                          }}
                        />
                        <FormControlLabel
                          value="disabled"
                          control={<Radio />}
                          label="Language 4"
                          style={{
                            border: "1px solid #D8D8DF",
                            padding: "10px 0px",
                            margin: "10px",
                            color: "#26252C",
                            fontSize: "16px",
                            width: "229px",
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "110%",
                            borderRadius: "4px",
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        flex={3}
                        marginLeft="-10px"
                      >
                        <FormControlLabel
                          value="disabled"
                          control={<Radio />}
                          label="Language 5"
                          style={{
                            border: "1px solid #D8D8DF",
                            padding: "10px 0px",
                            margin: "10px",
                            color: "#26252C",
                            fontSize: "16px",
                            width: "229px",
                            borderRadius: "4px",
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "110%",
                          }}
                        />
                        <FormControlLabel
                          value="disabled"
                          control={<Radio />}
                          label="Language 6"
                          style={{
                            border: "1px solid #D8D8DF",
                            padding: "10px 0px",
                            margin: "10px",
                            color: "#26252C",
                            fontSize: "16px",
                            width: "229px",
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "110%",
                            borderRadius: "4px",
                          }}
                        />
                        <FormControlLabel
                          value="disabled"
                          control={<Radio />}
                          label="Language 7"
                          style={{
                            border: "1px solid #D8D8DF",
                            padding: "10px 0px",
                            margin: "10px",
                            color: "#26252C",
                            fontSize: "16px",
                            width: "229px",
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "110%",
                            borderRadius: "4px",
                          }}
                        />
                        <FormControlLabel
                          value="disabled"
                          control={<Radio />}
                          label="Language 8"
                          style={{
                            border: "1px solid #D8D8DF",
                            padding: "10px 0px",
                            margin: "10px",
                            color: "#26252C",
                            fontSize: "16px",
                            width: "229px",
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "110%",
                            borderRadius: "4px",
                          }}
                        />
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                  <div
                    style={{
                      color: "#26252C",
                      fontFamily: "Work Sans",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "22px",
                      marginTop: "32px",
                    }}
                  >
                    <p> Password Change </p>
                  </div>
                  <Divider
                    className="horizontalLine"
                    style={{ marginLeft: "-3px", marginTop: "-2px" }}
                  />
                  <form action="" className="form">
                    {/* Current Password */}
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              marginLeft: "30px",
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            Current Password
                          </InputLabel>
                          <Input
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            placeholder="Enter Here"
                            id="input-with-icon-adornment"
                            startAdornment={
                              <InputAdornment position="start">
                                <img src={Email} alt="email" className="icon" />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>

                      {/* New Password */}
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              marginLeft: "30px",
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            New Password
                          </InputLabel>
                          <Input
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Enter Here"
                            id="standard-adornment-password"
                            // type={showPassword ? "text" : "password"}
                            // endAdornment={
                            // <InputAdornment position="end">
                            //   <IconButton
                            //     aria-label="toggle password visibility"
                            //     onClick={handleClickShowPassword}
                            //     onMouseDown={handleMouseDownPassword}
                            //   >
                            //     {showPassword ? (
                            //       <VisibilityOff />
                            //     ) : (
                            //       <Visibility />
                            //     )}
                            //   </IconButton>
                            // </InputAdornment>
                            // }
                            startAdornment={
                              <InputAdornment position="start">
                                <img
                                  src={Password}
                                  alt="password"
                                  className="icon"
                                />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>

                      {/* Confirm Password */}
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              marginLeft: "30px",
                              color: "#26252C",
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                          >
                            Confirm Password
                          </InputLabel>
                          <Input
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Enter Here"
                            id="standard-adornment-password"
                            // type={showPassword ? "text" : "password"}
                            // endAdornment={
                            //   <InputAdornment position="end">
                            //     <IconButton
                            //       aria-label="toggle password visibility"
                            //       onClick={handleClickShowPassword}
                            //       onMouseDown={handleMouseDownPassword}
                            //     >
                            //       {showPassword ? (
                            //         <VisibilityOff />
                            //       ) : (
                            //         <Visibility />
                            //       )}
                            //     </IconButton>
                            //   </InputAdornment>
                            // }
                            startAdornment={
                              <InputAdornment position="start">
                                <img
                                  src={Password}
                                  alt="password"
                                  className="icon"
                                />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      // display="flex"
                      // alignItems="center"
                      marginTop={3}
                      // width="300px"
                    >
                      <Button
                        onClick={handleChangePassword}
                        disabled={
                          currentPassword && newPassword && confirmPassword
                            ? false
                            : true
                        }
                        variant="contained"
                        type="submit"
                        sx={{
                          width: "300px",
                          textTransform: "none",
                          backgroundColor: "#00A6E8",
                          color: "white",
                        }}
                      >
                        Change Password
                      </Button>
                    </Grid>
                  </form>

                  <div
                    style={{
                      color: "#26252C",
                      fontFamily: "Work Sans",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "22px",
                      marginTop: "32px",
                    }}
                  >
                    <p> Permanent Delete My Account </p>
                  </div>
                  <Divider
                    className="horizontalLine"
                    style={{ marginLeft: "98px", marginTop: "-2px" }}
                  />
                  <Button
                    disabled
                    variant="outlined"
                    type="submit"
                    style={{
                      width: "300px",
                      textTransform: "none",
                      // backgroundColor: "#00A6E8",
                      color: "black",
                      marginBottom: "100px",
                    }}
                  >
                    Account Delete
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Account;
