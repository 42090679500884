import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";

// Image
import mainLogo from "../../assets/images/mainLogo.png";
import { useNavigate } from "react-router-dom";

const HeaderContainer = styled(Grid)`
  border-bottom: 1px solid #d8d8df;
  background: #fff;
  height: 72px;
`;

const LeftBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-left: 16px;
`;

const CenterBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Text = styled(Typography)`
  color: #000;
  font-family: Work sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const LogoImage = styled.img`
  cursor: pointer;
  max-width: 135px;
  height: auto;
`;

const CenterHeader = () => {
  const navigate = useNavigate();

  // Handle for go home
  const goHomeHandle = () => {
    navigate("/home");
  };

  return (
    <HeaderContainer container alignItems="center">
      <Grid item xs={4}>
        <LeftBox>
          <Text>Post New Ads</Text>
        </LeftBox>
      </Grid>
      <Grid item xs={4}>
        <CenterBox>
          <LogoImage src={mainLogo} alt="Logo" onClick={goHomeHandle} />
        </CenterBox>
      </Grid>
    </HeaderContainer>
  );
};

export default CenterHeader;
