import React, { useState } from "react";
import { Button, Divider, Box, Typography, Grid } from "@mui/material";
import styled from "@emotion/styled";

// Components
import CenterHeader from "../../../components/CenterHeader/CenterHeader";
import MarketPlace from "../../../components/PostNewAds/MarketPlace";

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #f5f5f5;
`;

const WhiteBox = styled(Box)`
  background-color: #fff;
  width: 100%;
  height: 100vh;
  min-height: 896px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const Heading = styled(Typography)`
  padding: 16px 0px 16px 32px;
  color: #000;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

const ButtonContainer = styled(Box)`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 24px;
  margin-right: 24px;
`;

const AdsCategoryText = styled(Typography)`
  color: #707187;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const Buttons = styled(Button)`
  border-radius: 4px;
  border: 0.5px solid #d8d8df;
  background: ${(props) => (props.selected ? "#00A6E8" : "#fff")};
  color: ${(props) => (props.selected ? "white" : "#707187")};
  &:hover {
    background: ${(props) => (props.selected ? "#00A6E8" : "#f0f0f0")};
    color: ${(props) => (props.selected ? "white" : "#707187")};
  }
`;

const AdsButtons = styled(Button)`
  border-radius: 4px;
  border: 0.5px solid #d8d8df;
  background: ${(props) => (props.adsSelected ? "#00A6E8" : "#fff")};
  color: ${(props) => (props.adsSelected ? "white" : "#707187")};
  &:hover {
    background: ${(props) => (props.adsSelected ? "#00A6E8" : "#f0f0f0")};
    color: ${(props) => (props.adsSelected ? "white" : "#707187")};
  }
`;

// Components
const ComponentFour = () => (
  <Typography variant="h6">This is the content for Button 4</Typography>
);

const ComponentFive = () => (
  <Typography variant="h6">This is the content for Button 5</Typography>
);

const ComponentSix = () => (
  <Typography variant="h6">This is the content for Button 6</Typography>
);

const ComponentSeven = () => (
  <Typography variant="h6">This is the content for Button 7</Typography>
);

const ComponentEight = () => (
  <Typography variant="h6">This is the content for Button 8</Typography>
);

const PostNewAdd = () => {
  const [selectedAdsTypeButton, setSelectedAdsTypeButton] = useState(null);
  const [selectedCategoryButton, setSelectedCategoryButton] = useState(null);

  const [marketFieldTextFilled, setMarketFieldTextFilled] = useState(true);
  const [btnDissabled, setBtnDissabled] = useState(true);

  const renderComponent = () => {
    switch (selectedCategoryButton) {
      case 2:
        return (
          <MarketPlace setMarketFieldTextFilled={setMarketFieldTextFilled} />
        );
      case 3:
        return <ComponentFour />;
      case 4:
        return <ComponentFive />;
      case 5:
        return <ComponentSix />;
      case 6:
        return <ComponentSeven />;
      case 7:
        return <ComponentEight />;
      default:
        return null;
    }
  };

  return (
    <>
      <CenterHeader />
      <Container>
        <WhiteBox>
          <Heading>Basic Information</Heading>
          <Divider />

          {/* Ads Type and Select Category */}
          <Grid container spacing={2} sx={{ mt: "24px", ml: "32px" }}>
            <Grid item xs={3}>
              <AdsCategoryText>Ads Type*</AdsCategoryText>
              <Box display="flex" gap="10px" mt="8px">
                <AdsButtons
                  adsSelected={selectedAdsTypeButton === 0}
                  onClick={() => {
                    setSelectedAdsTypeButton(0);
                    setBtnDissabled(false);
                  }}
                >
                  I Want to Sell
                </AdsButtons>
                <AdsButtons
                  adsSelected={selectedAdsTypeButton === 1}
                  onClick={() => {
                    setSelectedAdsTypeButton(1);
                    setBtnDissabled(false);
                  }}
                >
                  I am Looking for
                </AdsButtons>
              </Box>
            </Grid>

            {/* Select Category */}
            <Grid item xs={9}>
              <AdsCategoryText>Select Category</AdsCategoryText>
              <Box display="flex" gap="10px" mt="8px">
                <Buttons
                  selected={selectedCategoryButton === 2}
                  onClick={() => setSelectedCategoryButton(2)}
                  disabled={btnDissabled}
                >
                  Marketplace
                </Buttons>
                <Buttons
                  selected={selectedCategoryButton === 3}
                  onClick={() => setSelectedCategoryButton(3)}
                  disabled={btnDissabled}
                >
                  Automotive
                </Buttons>
                <Buttons
                  selected={selectedCategoryButton === 4}
                  onClick={() => setSelectedCategoryButton(4)}
                  disabled={btnDissabled}
                >
                  Properties
                </Buttons>
                <Buttons
                  selected={selectedCategoryButton === 5}
                  onClick={() => setSelectedCategoryButton(5)}
                  disabled={btnDissabled}
                >
                  Pets
                </Buttons>
                <Buttons
                  selected={selectedCategoryButton === 6}
                  onClick={() => setSelectedCategoryButton(6)}
                  disabled={btnDissabled}
                >
                  Bicycle & Spare Parts
                </Buttons>
                <Buttons
                  selected={selectedCategoryButton === 7}
                  onClick={() => setSelectedCategoryButton(7)}
                  disabled={btnDissabled}
                >
                  Electric Vehicle
                </Buttons>
              </Box>
            </Grid>
          </Grid>

          {/* Contents show */}
          <Box sx={{ mt: "24px", ml: "50px", mr: "50px" }}>
            <Divider />
            {renderComponent()}
          </Box>

          {/* Cancel and Next buttons */}
          <ButtonContainer>
            <Button variant="contained" color="secondary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={marketFieldTextFilled}
            >
              Next
            </Button>
          </ButtonContainer>
        </WhiteBox>
      </Container>
    </>
  );
};

export default PostNewAdd;
