import React from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";

// Images
import downArrow from "../../assets/images/CategoryImages/arrow-down.png";
import { useNavigate } from "react-router-dom";

const Category = ({ categories }) => {
  const navigate = useNavigate();


  const handleSelectCategory = (value) => {
    navigate(`/category/${value?.id}`);
    // console.log(item);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-evenly"
      padding="24px"
      sx={{
        background: "#fff",
        marginTop: "24px",
        borderRadius: "4px",
        border: "1px solid  #D8D8DF",
      }}
    >
      {/* Category options */}
      {categories.length > 0 && categories?.map((item) => (
        <Button
          fullWidth
          key={item.id}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => handleSelectCategory(item)}
        >
          {/* Image */}
          <Avatar
            src={item.icon}
            alt={item.name}
            sx={{ width: "64px", height: "64px" }}
          />

          {/* name and dropdwon icon */}
          <Box
            display="flex"
            alignItems="center"
            sx={{ gap: "4px", marginTop: "13px" }}
          >
            <Typography
              sx={{
                color: "#26252C",
                textAlign: "center",
                fontFamily: "Work Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: " 115%",
              }}
            >
              {item?.name}
            </Typography>
            <Avatar
              src={downArrow}
              alt="dropdown logo"
              sx={{ width: 16, height: 16 }}
            />
          </Box>
        </Button>
      ))}
    </Box>
  );
};

export default Category;
