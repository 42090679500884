import React, { useEffect } from "react";
import { Box } from "@mui/material";

// Components
import Header from "../../../components/Header/Header";
import Category from "../../../components/Cateory/Category";
import Banner from "../../../components/Banner/Banner";

// Menu item of categories
// import { categories } from "../../../helper/CategoriesOption";

// COmponents
import ProductSlide from "../../../components/ProductsSlide/ProductSlide";
import Footer from "../../../components/Footer/Footer";
import PopularCategory from "../../../components/PopularCategory/PopularCategory";
import { useDispatch, useSelector } from "react-redux";
import { fetchBannerList } from "../../../store/helper/banner";
import { fetchCategoryList } from "../../../store/helper/categorySlice";

function Home() {
  const dispatch = useDispatch();

  // Getting banner or advertisement and category
  useEffect(() => {
    dispatch(fetchBannerList());
    dispatch(fetchCategoryList());
  }, []);

  // Getting banner list from slice
  const bannerList = useSelector((state) => state?.bannerReducer?.data);

  // Category list
  const categoryList = useSelector((state) => state?.categoryReducer?.mainCategorydata);

  const shouldShowDiv = true; // Or false, depending on your logic


  return (
    <>
      <Box sx={{ dispay: "flex" }}>
        <Header isLoggedIn={true} />
        <Box sx={{ marginLeft: "16px", marginRight: "16px" }}>
          <Category categories={categoryList} />
          <Banner data={bannerList} />

          <Box sx={{ display: "flex", gap: "14px" }}>
            <ProductSlide title="Relative Products" />
            <Box
              sx={{
                width: "671px",
                // height: "371px",
                backgroundColor: "#fff",
                border: "1px solid #d8d8df",
                marginTop: "24px",
                borderRadius: "4px",
                textAlign: "-webkit-center",
                paddingTop: "180px",
                color: "grey",
                fontWeight: "bold",
              }}
            >
              Ads
            </Box>
          </Box>

          <ProductSlide title="Trendy Deal" showDiv={shouldShowDiv} />
        <ProductSlide title="Relative Products" />
        <ProductSlide title="Trendy Deal" />

          <PopularCategory />

          <Box sx={{ display: "flex", gap: "14px" }}>
            <Box
              sx={{
                width: "671px",
                // height: "371px",
                backgroundColor: "#fff",
                border: "1px solid #d8d8df",
                marginTop: "24px",
                borderRadius: "4px",
                textAlign: "-webkit-center",
                paddingTop: "180px",
                color: "grey",
                fontWeight: "bold",
              }}
            >
              Ads
            </Box>
            <ProductSlide title="Relative Products" />
          </Box>
        </Box>

        <Footer />
      </Box>
    </>
  );
}

export default Home;
